import React, { Fragment } from 'react';
import autoBind from 'react-autobind';
import { loginPost, logoutAction } from '../../actions/login';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "./login.css"
import { func, bool } from 'prop-types';
import * as Constant from "../../constants";
import cookies from "react-cookies";
import * as QueryString from "query-string"
import config from "../../local_config"

class Login extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = { username: '', password: '', redirect: '' };

        this.checkForLogout();
        let data = cookies.load(Constant.USER_DATA, { domain:  config.domainName });
        let token = cookies.load(Constant.ACCESS_TOKEN, { domain:  config.domainName})
        if (data && token) {
            this.props.history.push("/");
        }

    }

    checkForLogout() {
        const params = QueryString.parse(this.props.location.search);
        console.log(params);
        if (params.action && params.action === 'logout') {
            this.props.logoutAction(this.props.history)
        } else if (params.next) {
            this.props.logoutAction()
        }
    }

    checkForVerificationStatus() {
        const queryParams = new URLSearchParams(window.location.search);
        const paramValue = queryParams.get('isEmailVerified');
        if(paramValue){
            let message="";
            if(paramValue === "invalid-token"){
                message = "Invalid verification token, Please register again";
            }
            else{
                message = "Your email is already verified"
            }
            return (
                <div class="alert alert-danger alert-dismissible fade show">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    {message}
                </div>
            )
        }
    }

    /**
     * Handling input change events.
     * @param  {Object} event 
     */
    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }

    /**
     * Form submit function with validation check.
     * @param  {Object} event 
     */
    formSubmit = (event) => {
        event.preventDefault();
        const data = {
            username: this.state.username,
            password: this.state.password
        }
        this.props.loginPost(data,this.props.history);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
            const params = QueryString.parse(prevProps.location.search);
            if (params.next) {
                window.location = params.next;
            } else {
                this.props.history.push("/");
            }
        }
    }


    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row justify-content-center align-items-center height80Vh">
                        <div className="col-10 col-md-7 col-lg-4 loginBox">
                            <form onSubmit={this.formSubmit} style={{ marginTop: '-10px' }} className="justify-content-center">
                                <div className="text-center">
                                    {this.checkForVerificationStatus()}
                                    <h3>Unirac SSO</h3>
                                </div>
                                <hr></hr>
                                <div className="form-group">
                                    <label>Email address</label>
                                    <input type="email" className="form-control" value={this.state.username}
                                        onChange={this.handleInputChange} name="username" required placeholder="Enter email" />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control" value={this.state.password}
                                        onChange={this.handleInputChange} name="password" required placeholder="Enter password" />
                                </div>
                                <button type="submit" className="btn btn-block submitButtonColor">Submit</button>
                            </form>
                            <div className="text-center loginRegNavLink">
                                <a href={config.forgotPasswordLink}>Reset password</a>
                            </div>
                            <div className="text-center loginRegNavLink">
                                <Link to={'/registration'}>Not a member? Register!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        isLoggedIn: state.loginInfo.isLoggedIn
    }
}

Login.propTypes = {
    loginPost: func,
    isLoggedIn: bool
}

export default connect(mapStateToProps, { loginPost, logoutAction })(withRouter(Login))