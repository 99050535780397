import React from 'react';
import './App.css';
import { connect } from "react-redux";
import LoadingSpinner from './components/loadingSpinner/loadingSpinner';
import Navbar from './components/navbar/navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Dashboard from './components/dashboard/dashboard'
import Login from './components/login/login'
import PrivateRoute from './components/privateroute/privateroute'
import VerifyEmail from './components/verifyEmail/verifyEmail';
import ProfileVerified from './components/profileVerified/profileVerified';
import { isLoggedIn } from "./actions/login";
import * as Constant from "./constants";
import cookie from "react-cookies";
import Registration from './components/registration/registration';
import { ToastsContainer,ToastsContainerPosition, ToastsStore } from 'react-toasts';



class App extends React.Component {

  constructor(props) {
    super(props)
    let data = cookie.load(Constant.USER_DATA);
    if (data) {
      props.isLoggedIn(data)
    }

  }

  render() {
    const isLoading = this.props.isLoading;

    return (
      <div className="App">
        {isLoading && <LoadingSpinner></LoadingSpinner>}

        <Router>
          <Navbar></Navbar>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/registration">
              <Registration />
            </Route>
            <Route exact path="/verifyEmail">
              <VerifyEmail />
            </Route>
            <Route exact path="/profileVerified">
              <ProfileVerified />
            </Route>
            <PrivateRoute exact component={Dashboard} path="/" ></PrivateRoute>
          </Switch>
        </Router>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} lightBackground />

      </div>
    );
  }
}



function mapStateToProps(state, props) {
  return {
    isLoading: state.apiCalls.isLoading
  }
}


export default connect(mapStateToProps, { isLoggedIn })(App);
