import React, { Component } from 'react';
import "./navbar.css"
import { connect } from "react-redux";
import { logoutAction } from "../../actions/login";
import { withRouter, Link } from "react-router-dom";
import autoBind from 'react-autobind';
import { func, bool } from 'prop-types';
import unirac from '../../static/unirac.svg';
import avtar from "../../static/avtar.png";

class NavBar extends Component {


    constructor(props) {
        super(props);
        autoBind(this);

    }

    logout() {
        this.props.logoutAction(this.props.history);
    }

    render() {

        const isLoggedIn = this.props.isLoggedIn;
        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-sm navbar-light bg-light border-bottom border-primary"  >
                    <a className="navbar-brand navbar-brand-border-right" href="/">
                        <img
                            src={unirac} className="d-inline-block align-top" alt="" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon navbar-toggler-icon-blue"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item navbar-brand-border-right">
                                <a href="/" className="nav-link fontSize" >SINGLE SIGN ON</a>
                            </li>
                            {isLoggedIn && <li className="nav-item">
                                <Link className="nav-link fontSize" to={"/"}>Home</Link>
                            </li>}
                            {/* <li className="nav-item">
                                <Link className="nav-link fontSize" to={"/"}>Home</Link>
                            </li> */}
                        </ul>
                        {isLoggedIn && <UserMenu logout={this.logout} userInfo={this.props.userInfo} ></UserMenu>}
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.loginInfo.isLoggedIn,
        userInfo: state.loginInfo.userInfo
    }
}
export default connect(mapStateToProps, { logoutAction })(withRouter(NavBar));


export const UserMenu = ({ logout, userInfo }) => {
    const username = userInfo.username;

    return (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown align-self-center">
                <div id="navbarDropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                    <img height="40" width="40"
                        className="nav-link imageMargin"
                        src={avtar} alt="" >
                    </img>
                    {username}
                </div>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {/* <Link className="dropdown-item" to={"/changepassword"}>Change Password</Link> */}
                    <span className="dropdown-item cursorPointer" onClick={() => { logout() }} >Logout</span>
                </div>
            </li>
        </ul>
    )
}


NavBar.propTypes = {
    logoutAction: func,
    isLoggedIn: bool
}