import React, { Fragment } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import "./dashboard.css";
import ubuilder from "../../static/ubuilder.png";
import { connect } from "react-redux";
import unirac from "../../static/unirac.svg";
import { loginPost } from "../../actions/login";
import cookies from "react-cookies";
import * as Constant from "../../constants";
import { Redirect } from "react-router-dom";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const data = {
      token: cookies.load(Constant.ACCESS_TOKEN),
    };
    this.props.loginPost(data);
  }

  render() {
    const isLoggedIn = this.props.isLoggedIn;
    if (isLoggedIn) {
      return (
        <Fragment>
          <div className="col-lg-12 topMargin">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">List of Applications</div>
                  <div className="card-body">
                    <div className="row">{listOfApplicationCard(this.props.userInfo.application_list)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <Redirect to="/login"></Redirect>;
    }
  }
}

const listOfApplicationCard = (applicationList) => {
  // const data = applicationList.map((value, index) => {
  let val = Object.entries(applicationList).map(([key, value], index) => {
    return (
      <div key={key} className="col-lg-2 col-md-4 col-sm-6">
        <div className="card  text-center">
          <div className="card-image">
            <a href={getRedirectionUrl(value)} id="aTag" target="_blank" rel="noopener noreferrer">
              <img src={getImage(value.application_name)} alt=""></img>
              <br></br>
              <span>{value.application_name}</span>
            </a>
          </div>
        </div>
      </div>
    );
  });

  return val;
};

const getRedirectionUrl = (app) => {
  return app.has_access ? app.application_url : app.application_landing_page;
};

const getImage = (name) => {
  if (name.toLocaleLowerCase().includes("ubuilder")) {
    return ubuilder;
  } else {
    return unirac;
  }
};

Dashboard.propTypes = {
  reviews: PropTypes.array,
};

function mapStateToProps(state, props) {
  return {
    userInfo: state.loginInfo.userInfo,
    isLoggedIn: state.loginInfo.isLoggedIn,
  };
}
export default connect(mapStateToProps, { loginPost })(Dashboard);
