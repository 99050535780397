import React, { Fragment } from "react";
import * as Constant from "../../constants";
import { connect } from "react-redux";
import {  Link,withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import cookie from "react-cookies";
import "./registration.css";
import { registerUser } from "../../actions/login";

class Registration extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);

        let data = cookie.load(Constant.USER_DATA);
        if (data) {
            this.props.history.push("/");
        }
        this.state = { email: '', password: '', password_repeat: '', error: { email: '', password: '', password_repeat: '' }, buttonDisable: false };
    }
    /**
     * Handling input change events.
     * @param  {Object} event 
     */
    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
        this.validateInputFields(name,value);
    }

    validateInputFields = (fieldName, value) => {
        let disable = false, errorMessage=null;
        if(fieldName==="email"){
            if(!value.includes("@")){
                disable = true;
                errorMessage = "Please enter valid email address";  
            }
            this.setState({
                error: {
                    ...this.state.error,
                    [fieldName]: errorMessage,
                },
            })
        }
        if(fieldName==="password"){
            if(value.length < 8){
                disable = true;
                errorMessage = "The password field must be contain atleast 8 characters";  
            }
            this.setState({
                error: {
                    ...this.state.error,
                    [fieldName]: errorMessage,
                },
            })
        }
        if(fieldName === "password_repeat"){
            if(value !== this.state.password){
                disable = true;
                errorMessage = "Passwords doesn't match";
            }
            this.setState({
                error: {
                    ...this.state.error,
                    [fieldName]: errorMessage,
                },
            })
        }
        this.setState({
            buttonDisable: disable
        })

    }
    /**
       * Form submit function with validation check.
       * @param  {Object} event 
       */
    formSubmit = (event) => {
        event.preventDefault();
        this.props.registerUser(this.state, this.props.history);

    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row justify-content-center align-items-center height80Vh">
                        <div className="col-10 col-md-7 col-lg-4 loginBox">
                            <form onSubmit={this.formSubmit} style={{ marginTop: '-10px' }} className="justify-content-center">
                                <div className="text-center">
                                    <h3>Unirac Registration</h3>
                                </div>
                                <hr></hr>
                                <div className="form-group">
                                    <label>Email </label>
                                    <input type="email" className={`form-control ${this.state.error.email ? "invalid" : ""}`} value={this.state.email}
                                        onChange={this.handleInputChange} name="email" required placeholder="Enter email" />
                                    <span className="error-message">{this.state.error.email}</span>

                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" className={`form-control ${this.state.error.password ? "invalid" : ""}`} value={this.state.password}
                                        onChange={this.handleInputChange} name="password" required placeholder="Enter password" />
                                    <span className="error-message">{this.state.error.password}</span>
                                </div>
                                <div className="form-group">
                                    <label>Repeat Password</label>
                                    <input type="password" className={`form-control ${this.state.error.password_repeat ? "invalid" : ""}`} value={this.state.password_repeat}
                                        onChange={this.handleInputChange} name="password_repeat" required placeholder="Enter password again" />
                                    <span className="error-message">{this.state.error.password_repeat}</span>
                                </div>
                                <button type="submit" className="btn btn-block submitButtonColor" disabled={this.state.buttonDisable}>Register</button>
                            </form>
                            <div className="text-center loginRegNavLink">
                                <Link to={'/login'}>Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default connect(null, { registerUser })(withRouter(Registration))